:global(.List-section-header .Icon),
:global(.List-item .List-item-arrow .Icon) {
  color: var(--color-text-dark);
}

:global(.List-section-header:hover .Icon) {
  color: inherit;
}

:global(.List-item .Icon) {
  color: var(--color-text-light);
}

:global(.List-section-header) {
  color: var(--color-text-dark);
  border: 2px solid transparent;

  /* so that spacing matches .List-item */
}

:global(.List-section-header--cursor) {
  border-radius: 8px;
  outline: 2px solid var(--color-focus);
  outline-offset: -4px;
}

/* these crazy rules are needed to get currentColor to propagate to the right elements in the right states */
:global(.List-section--togglable .List-section-header:hover),
:global(.List-section--togglable .List-section-header:hover .Icon),
:global(.List-section--togglable
    .List-section-header:hover
    .List-section-title),
:global(.List-section--expanded .List-section-header),
:global(.List-section--expanded .List-section-header .List-section-icon .Icon) {
  color: currentColor;
}

:global(.List-section--expanded .List-section-header .List-section-title) {
  color: var(--color-text-dark);
}

:global(.List-section-title) {
  word-wrap: break-word;
}

/* LIST ITEM */
:global(.List-item) {
  display: flex;
  border-radius: 4px;
  margin-top: 2px;
  margin-bottom: 2px;
}

:global(.List-item--cursor) {
  outline: 2px solid var(--color-focus);
}

:global(.List-item--cursor:not(.List-item--disabled)),
:global(.List-item:not(.List-item--disabled):hover),
:global(.List-item--selected:not(.List-item--disabled)) {
  background-color: currentColor;
}

/* LIST ITEM TITLE */
:global(.List-item-title) {
  color: var(--color-text-dark);
}

:global(.List-item--disabled .List-item-title) {
  color: var(--color-text-light);
}

:global(.List-item--cursor:not(.List-item--disabled) .List-item-title),
:global(.List-item:not(.List-item--disabled):hover .List-item-title),
:global(.List-item--selected:not(.List-item--disabled) .List-item-title) {
  color: white;
}

/* LIST ITEM DESCRIPTION */
:global(.List-item-description) {
  margin-top: 0.25em;
  margin-bottom: 0;
  color: var(--color-text-medium);
}

:global(.List-item--disabled .List-item-description) {
  color: var(--color-text-dark);
}

:global(.List-item--cursor:not(.List-item--disabled) .List-item-description),
:global(.List-item:not(.List-item--disabled):hover .List-item-description),
:global(.List-item--selected:not(.List-item--disabled) .List-item-description) {
  color: rgba(255, 255, 255, 0.5);
}

/* LIST ITEM ICON */
:global(.List-item--cursor:not(.List-item--disabled) .Icon),
:global(.List-item:not(.List-item--disabled):hover .Icon),
:global(.List-item--selected:not(.List-item--disabled) .Icon) {
  color: white !important;
}
