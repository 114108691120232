:root {
  --modal-background-color: color-mod(var(--color-bg-black) alpha(-40%));
  --modal-background-color-transition: color-mod(
    var(--color-bg-black) alpha(-99%)
  );
}

:global(.ModalContainer) {
  z-index: 4;
}

:global(.Modal) {
  margin: auto;
  width: 640px;
  box-shadow: 0 0 6px var(--color-shadow);
  max-height: 90%;
  overflow-y: auto;
}

/* On IE11, single flex item with `margin: auto` gets shifted to flex end
 * https://github.com/philipwalton/flexbugs/issues/157
 * Set margin to zero when using Flexbox in `WindowModal` component
 */
:global(.Modal-backdrop > .Modal) {
  margin: 0;
}

:global(.Modal.Modal--small) {
  width: 480px;
} /* TODO - why is this one px? */
:global(.Modal.Modal--medium) {
  width: 65%;
}

:global(.Modal.Modal--wide) {
  width: 85%;
}

:global(.Modal.Modal--fit) {
  width: auto;
  max-height: 100%;
}

:global(.Modal.Modal--tall) {
  min-height: 85%;
}

:global(.Modal--full) {
  background-color: white;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
}

:global(.Modal-backdrop) {
  background-color: color-mod(var(--color-bg-black) alpha(-40%));
}

/* TRANSITIONS */

/* backdrop */

:global(.Modal-backdrop.Modal-appear),
:global(.Modal-backdrop.Modal-enter) {
  background-color: color-mod(var(--color-bg-black) alpha(-99%));
}

:global(.Modal-backdrop.Modal-appear-active),
:global(.Modal-backdrop.Modal-enter-active) {
  transition: background-color 200ms ease-in-out;
  background-color: color-mod(var(--color-bg-black) alpha(-40%));
}

:global(.Modal-backdrop.Modal-exit) {
  background-color: color-mod(var(--color-bg-black) alpha(-40%));
}

:global(.Modal-backdrop.Modal-exit-active) {
  transition: background-color 200ms ease-in-out 100ms;
  background-color: color-mod(var(--color-bg-black) alpha(-99%));
}

/* modal */

:global(.Modal-backdrop.Modal-appear .Modal),
:global(.Modal-backdrop.Modal-enter .Modal) {
  opacity: 0.01;
  transform: translate(0, 40px);
}

:global(.Modal-backdrop.Modal-appear-active .Modal),
:global(.Modal-backdrop.Modal-enter-active .Modal) {
  transition: opacity 200ms linear 100ms, transform 200ms ease-in-out 100ms;
  opacity: 1;
  transform: translate(0, 0);
}

:global(.Modal-backdrop.Modal-exit .Modal) {
  opacity: 1;
  transform: translate(0, 0);
}

:global(.Modal-backdrop.Modal-exit-active .Modal) {
  transition: opacity 200ms linear, transform 200ms ease-in-out;
  opacity: 0.01;
  transform: translate(0, -40px);
}

@media (prefers-reduced-motion) {
  :global(.Modal-backdrop.Modal-appear),
  :global(.Modal-backdrop.Modal-enter),
  :global(.Modal-backdrop.Modal-exit),
  :global(.Modal-backdrop.Modal-appear .Modal),
  :global(.Modal-backdrop.Modal-enter .Modal),
  :global(.Modal-backdrop.Modal-exit .Modal) {
    transition: none;
  }
}
